import {
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonHeader,
  IonPage,
  IonSpinner,
  IonSplitPane,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import Header from "../../components/header/Header";
import StartModal from "../../components/modals/StartModal";
import "./Workshop.scss";

import LandingImage from "../../assets/img/Workshop Left_.png";

import { RouteComponentProps, useHistory } from "react-router";
import { useEffect, useRef, useState } from "react";
import {
  brands,
  businessSub,
  updateBusiness,
  updateWorkshop,
  workshopSub,
} from "../../services/data.service";
import { BusinessModel, WorkshopModel } from "../../models/business.class";
import { Geocode, initService } from "../../services/location.service";

import { filterBy } from "@progress/kendo-data-query";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import {
  DropDownList,
  MultiSelect,
  MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { RegisterModel } from "../../models/RegisterModel";
import { WorkShopRmiData } from "../../services/rmiData";

interface UserDetailPageProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const Onboarding: React.FC<UserDetailPageProps> = ({ match }) => {
  const history = useHistory();
  const [locations, setLocations] = useState([]);
  const [address, setAddress] = useState("");
  const [clicked, setClicked] = useState(false);
  const [currentStep, setStep] = useState(Number(match.params.id));
  const [province, setProvince] = useState("");
  const [workShopCompanyNames] = useState(WorkShopRmiData);
  const [filterWorkShop, setFilterWorkShop] = useState(workShopCompanyNames);
  // const [workshopFiletr, setWorkshopFiletr] = useState("");

  let [registerDetails, setRegisterDetails] = useState(
    new RegisterModel({
      name: "",
      surname: "",
      phone_number: "",
      email: "",
      password: "",
      confirmpassword: "",
      rmi: "",
      workshop_name: "",
      bank_account_no: "",
      bank_account_type: "",
      bank_branch_code: "",
      bank: "",
      reg_no: "",
      vat_no: "",
      vehicles: [],
      rate: 0,
      rate_discount: 5,
      parts_discount: 5,
      province: "",
      new_cars: false,
      old_cars: false,
      weekdayStartTime: "09:00 AM",
      weekdayEndTime: "05:00 PM",
      saturdayStartTime: "09:00 AM",
      saturdayEndTime: "02:00 PM",
      sundayStartTime: "12:00 PM",
      sundayEndTime: "04:00 PM",
      isWeekdayWorking: false,
      isSaturdayWorking: false,
      isSundayWorking: false,
      initial_hours: 4,
    })
  );

  const [showPricePopup, setShowPricePopup]: any = useState(false);
  const [showHoursPopup, setShowHoursPopup]: any = useState(false);

  const [provinces, setProvinces]: any = useState([
    { name: "Eastern Cape", id: 1 },
    { name: "Free State", id: 2 },
    { name: "Gauteng", id: 3 },
    { name: "KwaZulu-Natal", id: 4 },
    { name: "Limpopo", id: 5 },
    { name: "Mpumalanga", id: 6 },
    { name: "North West", id: 7 },
    { name: "Northern Cape", id: 8 },
    { name: "Western Cape", id: 9 },
  ]);

  const [vehicles, setVehicles]: any = useState([
    { name: "Toyota", id: 1 },
    { name: "VW", id: 2 },
    { name: "BMW", id: 3 },
    { name: "Jeep", id: 4 },
  ]);

  const [banks, setBanks]: any = useState([
    { name: "Absa", id: 1 },
    { name: "FNB", id: 2 },
    { name: "Capitec", id: 3 },
    { name: "Nedbank", id: 4 },
    { name: "Investec", id: 5 },
    { name: "Tyme Bank", id: 6 },
    { name: "Discovery", id: 7 },
    { name: "African Bank", id: 8 },
    { name: "Bank Zero", id: 9 },
    { name: "Bidvest Bank", id: 10 },
    { name: "Rand Merchant Bank", id: 11 },
    { name: "Standard Bank", id: 12 },
  ]);

  const [accounts, setAccounts]: any = useState([
    { name: "Cheque", id: 1 },
    { name: "Savings", id: 2 },
    { name: "Transaction", id: 3 },
  ]);

  useEffect(() => {
    // @ts-ignore
    const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
    registerDetails = userDetails;
    registerDetails.old_cars = false;
    registerDetails.new_cars = false;
    registerDetails.isSaturdayWorking = false;
    registerDetails.isSundayWorking = false;
    registerDetails.isWeekdayWorking = false;
    registerDetails.saturdayStartTime = "";
    registerDetails.saturdayEndTime = "";
    registerDetails.weekdayStartTime = "";
    registerDetails.weekdayEndTime = "";
    registerDetails.sundayStartTime = "";
    registerDetails.sundayEndTime = "";
    setRegisterDetails(registerDetails);

    setRegisterDetails(new RegisterModel(registerDetails));

    brands.subscribe((brandDetails: any) => {
      const allMakesOption = { name: "Select All Makes", id: "all" };
      const updatedVehiclesData = [allMakesOption, ...brandDetails.slice()];
      setVehicles({
        data: updatedVehiclesData,
        loading: false,
      });
    });
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    const query = window.document.querySelector(
      ".right-scrollable"
    ) as HTMLElement;
    query.scrollTo(0, 0);
  };

  const submitWorkshop = () => {
    window.localStorage.setItem("userDetails", JSON.stringify(registerDetails));

    history.push("/confirm");
  };
  const delay = 500;
  const timeout = useRef<any>();

  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      brands.subscribe((d: any) => {
        setVehicles({
          data: filterBy(d.slice(), event.filter),
          loading: false,
        });
      });
    }, delay);
  };

  const handleVehicleChange = (ev: any) => {
    const { value } = ev;
    if (value.some((v: any) => v.id === "all")) {
      setRegisterDetails((prevDetails) => ({
        ...prevDetails,
        vehicles: vehicles.data.filter((v: any) => v.id !== "all"),
      }));
    } else {
      setRegisterDetails((prevDetails) => ({
        ...prevDetails,
        vehicles: value,
      }));
    }
  };

  // const handleWorkshopFilterChange = (event: any) => {
  //   const nameFilter = event.filter.value || "";
  //   setWorkshopFiletr(nameFilter);

  //   if (nameFilter) {
  //     const isFiltered = workShopCompanyNames.filter((item: any) =>
  //       item.Workshop.toLowerCase().includes(nameFilter.toLowerCase())
  //     );
  //     setFilterWorkShop(isFiltered);
  //     setWorkshopFiletr(nameFilter);
  //   } else {
  //     setFilterWorkShop(workShopCompanyNames);
  //   }
  // };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <Header step={currentStep} stepper={true} />
          <div className="container">
            <div className="row mt-5">
              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-7">
                {
                  // WORKSHOP STEP ONE
                  currentStep === 1 ? (
                    <h3 className="fw-light headline-text">Workshop Setup</h3>
                  ) : currentStep === 2 ? (
                    <h3 className="fw-light headline-text">
                      Operating Conditions
                    </h3>
                  ) : // : currentStep === 3 ? (
                  //   <h3 className="fw-light headline-text">

                  //     Business Details
                  //   </h3>
                  // )
                  currentStep === 4 ? (
                    <h3 className="fw-light headline-text">
                      Confirm your details
                    </h3>
                  ) : null
                }
                <img className="left-image" src={LandingImage} />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 right-scrollable">
                {
                  // WORKSHOP STEP TWO
                  currentStep === 2 ? (
                    <>
                      <button
                        className="btn btn-outline-warning btn-lg mb-3"
                        type="button"
                        onClick={() => {
                          // updateWorkshop(registerDetails);
                          setStep(1);
                          scrollToTop();
                          history.push("/onboarding/1");
                        }}
                      >
                        Back
                      </button>
                      <div className="card mb-3">
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-0">
                            Select the vehicles your workshop is qualified to
                            service.
                          </h6>
                          <div className="spacer"></div>
                        </div>
                        <MultiSelect
                          autoClose={false}
                          placeholder="Search Make"
                          data={vehicles.data}
                          value={registerDetails.vehicles}
                          filterable={true}
                          onFilterChange={filterChange}
                          textField="name"
                          dataItemKey="name"
                          onChange={(ev) => handleVehicleChange(ev)}
                        />
                        <div className="spacer"></div>
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-0">
                            Select the ages of the cars you work on
                          </h6>
                          <div className="spacer"></div>
                        </div>
                        <div className="form-control p-3 fill">
                          <p className="l-1 fw-light m-0 d-flex justify-content-between">
                            0 - 10 Years
                            <IonCheckbox
                              slot="end"
                              checked={registerDetails.new_cars}
                              defaultChecked={registerDetails.new_cars}
                              mode="md"
                              color="warning"
                              onIonChange={(ev) => {
                                registerDetails.new_cars = ev.detail.checked;
                                setRegisterDetails(
                                  new RegisterModel(registerDetails)
                                );
                              }}
                            ></IonCheckbox>
                          </p>
                        </div>
                        <div className="form-control p-3 fill">
                          <p className="l-1 fw-light m-0 d-flex justify-content-between">
                            10+ Years
                            <IonCheckbox
                              slot="end"
                              defaultChecked={registerDetails.new_cars}
                              checked={registerDetails.old_cars}
                              mode="md"
                              color="warning"
                              onIonChange={(ev) => {
                                registerDetails.old_cars = ev.detail.checked;
                                setRegisterDetails(
                                  new RegisterModel(registerDetails)
                                );
                              }}
                            ></IonCheckbox>
                          </p>
                        </div>
                        <p className="mb-0">
                          <strong>NOTE:</strong> You may select more than one
                          option
                        </p>

                        <div className="spacer"></div>
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-0">
                            Set your default retail labour rate, and potential
                            discounts.
                          </h6>
                          <div className="spacer"></div>
                          <div className="row">
                            <div className="col">
                              <label className="form-label fw-bolder">
                                Labour rate (Rands per hour - Excluding VAT)
                              </label>
                              <div className="input-group w-100">
                                <input
                                  className="form-control"
                                  type="number"
                                  min="1"
                                  max="5000"
                                  defaultValue={registerDetails.rate}
                                  onChange={(ev) => {
                                    registerDetails.rate = ev.target.value;
                                    setRegisterDetails(
                                      new RegisterModel(registerDetails)
                                    );
                                    // if(registerDetails.rate < 1 || registerDetails.rate > 5000) {
                                    //   alert('Rate should be between 1 to 5000')
                                    // }
                                  }}
                                />
                              </div>
                              {(registerDetails.rate < 1 ||
                                registerDetails.rate > 5000) && (
                                <p className="text-danger">
                                  Rate should be between 1 to 5000
                                </p>
                              )}
                            </div>
                            <div className="col">
                              <label className="form-label fw-bolder">
                                Discount
                              </label>
                              <div className="input-group w-100">
                                <input
                                  className="form-control"
                                  type="number"
                                  min="5"
                                  max="100"
                                  defaultValue={registerDetails.rate_discount}
                                  onChange={(ev) => {
                                    registerDetails.rate_discount =
                                      ev.target.value;
                                    setRegisterDetails(
                                      new RegisterModel(registerDetails)
                                    );
                                    // if(registerDetails.rate_discount < 5 || registerDetails.rate_discount > 100) {
                                    //   alert('Discount should be between 5 to 100')
                                    // }
                                  }}
                                />
                                <span className="input-group-text">%</span>
                              </div>
                              {(registerDetails.rate_discount < 5 ||
                                registerDetails.rate_discount > 100) && (
                                <p className="text-danger">
                                  Discount should be between 5 to 100
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <label className="form-label fw-bolder">
                                Discount on parts
                              </label>
                              <div className="input-group w-100">
                                <input
                                  className="form-control"
                                  type="number"
                                  min="5"
                                  max="100"
                                  defaultValue={registerDetails.parts_discount}
                                  onChange={(ev) => {
                                    registerDetails.parts_discount =
                                      ev.target.value;
                                    setRegisterDetails(
                                      new RegisterModel(registerDetails)
                                    );
                                    // if(registerDetails.parts_discount < 5 || registerDetails.parts_discount > 100) {
                                    //   alert('Discount on parts should be between 5 to 100')
                                    // }
                                  }}
                                />
                                <span className="input-group-text">%</span>
                              </div>
                              {(registerDetails.parts_discount < 5 ||
                                registerDetails.parts_discount > 100) && (
                                <p className="text-danger">
                                  Discount on parts should be between 5 to 100
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="spacer"></div>
                          <div style={{ position: "relative" }}>
                            <div
                              className={
                                showPricePopup ? "pop-up show" : "pop-up"
                              }
                            >
                              <p
                                onClick={() =>
                                  setShowPricePopup(!showPricePopup)
                                }
                                className="close-icon"
                              >
                                x
                              </p>
                              <p>
                                Servisor sets a minimum of 5% discount on all
                                labour and parts.
                              </p>
                              <p>
                                Your discount percentage can be changed on a day
                                to day basis within your workshop dashboard.
                              </p>
                            </div>
                            <p>
                              <small>
                                <a
                                  onClick={() => setShowPricePopup(true)}
                                  className="font-bold"
                                >
                                  What does this mean?
                                </a>
                              </small>
                            </p>
                          </div>
                        </div>

                        {/* This the part for hours */}
                        <div className="spacer"></div>
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-0">
                            How many workshop hours per day would you like to
                            allocate to bookings from Servisor?
                          </h6>
                          <div className="spacer"></div>
                          <div className="row">
                            <div className="col-6">
                              <label className="form-label fw-bolder">
                                Allocated hours per day
                              </label>
                              <div className="input-group w-100">
                                <input
                                  className="form-control"
                                  type="number"
                                  min="2"
                                  defaultValue={registerDetails.initial_hours}
                                  onChange={(ev) => {
                                    registerDetails.initial_hours =
                                      ev.target.value;
                                    setRegisterDetails(
                                      new RegisterModel(registerDetails)
                                    );
                                  }}
                                />
                                <span className="input-group-text">hrs</span>
                              </div>
                              {registerDetails.initial_hours < 2 && (
                                <p className="text-danger">
                                  Minimum of 2 hours is required
                                </p>
                              )}
                            </div>
                          </div>
                          <p className="mb-0">
                            <strong>NOTE:</strong> 4 hours = 1 Major service or
                            2 minor services per day.
                          </p>
                          <div className="spacer"></div>
                          <div style={{ position: "relative" }}>
                            <div
                              className={
                                showHoursPopup ? "pop-up show" : "pop-up"
                              }
                            >
                              <p
                                onClick={() =>
                                  setShowHoursPopup(!showHoursPopup)
                                }
                                className="close-icon"
                              >
                                x
                              </p>
                              <p>
                                Maximize your workshop's potential by allocating
                                your unused hours to Servisor. The more hours
                                you dedicate, the more you can boost your
                                workshop's throughput.
                              </p>
                              <p>
                                A minimum of 2 hours per day is required, but we
                                recommend at least 4 hours daily.
                              </p>
                            </div>
                            <p>
                              <small>
                                <a
                                  onClick={() => setShowHoursPopup(true)}
                                  className="font-bold"
                                >
                                  What does this mean?
                                </a>
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="card mb-3">
                              <div className="card-body p-0">
                                  <h6 className="card-title fw-bolder mb-0">How many workshop hours per day would you like to allocate to bookings from Servisor?</h6>
                                  <div className="spacer"></div>
                              </div>
                              <div className="row">
                                  <div className="col-6">
                                    <label className="form-label fw-bolder">Allocated hours per day</label>
                                    <div className="input-group w-100">
                                      <input className="form-control" type="number" min="2" max="8"  value={workshop.workshop_hours} onChange={(ev) => {
                                        workshop.workshop_hours =  String(ev.target.value);
                                        setWorkshop(new WorkshopModel(workshop))
                                      }} />
                                      <span className="input-group-text">Hrs</span>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label className="form-label fw-bolder">Service Bays</label>
                                    <div className="input-group w-100">
                                      <input className="form-control" type="number"  min="1" max="100" value={workshop.slots_per_hour} onChange={(ev) => {
                                        workshop.slots_per_hour = ev.target.value;
                                        setWorkshop(new WorkshopModel(workshop))
                                      }} />
                                      <span className="input-group-text">Bays</span>
                                    </div>
                                  </div>
                              </div>
                              <div className="spacer"></div>
                              <div style={{'position': 'relative'}}>
                                  <div className={showHoursPopup ? 'pop-up show' : 'pop-up'}>
                                      <p onClick={() => setShowHoursPopup(!showHoursPopup)} className="close-icon">x</p>
                                      <p>A minimum of 2 hours per day is allocated to Servisor.</p>
                                      <p>Your allocated hours can be changed on a day to day basis within your workshop dashboard.</p>
                                  </div>
                                  <p><small><a onClick={() => setShowHoursPopup(true)} className="font-bold">What does this mean?</a></small></p>
                              </div>
                          </div>
 */}
                      <div className="card mb-3">
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-0">
                            What are your workshop operating hours?
                          </h6>
                          <div className="spacer"></div>
                          <div className="d-flex justify-content-between mb-2 align-items-center">
                            <label className="form-label fw-bolder">
                              Monday - Friday
                            </label>
                            <IonToggle
                              mode="ios"
                              onIonChange={(ev) => {
                                registerDetails.isWeekdayWorking =
                                  ev.detail.checked;
                                if (!ev.detail.checked) {
                                  registerDetails.weekdayStartTime = "";
                                  registerDetails.weekdayEndTime = "";
                                }
                                setRegisterDetails(
                                  new RegisterModel(registerDetails)
                                );
                              }}
                            ></IonToggle>
                          </div>
                          {registerDetails.isWeekdayWorking === true ? (
                            <>
                              <div className="row">
                                <div className="col">
                                  <div className="input-group w-100">
                                    <IonDatetime
                                      displayFormat="h:mm A"
                                      pickerFormat="h:mm A"
                                      value={registerDetails.weekdayStartTime}
                                      defaultValue={
                                        registerDetails.weekdayStartTime
                                      }
                                      onIonChange={(ev: any) => {
                                        registerDetails.weekdayStartTime =
                                          ev.detail.value;
                                        setRegisterDetails(
                                          new RegisterModel(registerDetails)
                                        );
                                      }}
                                    ></IonDatetime>
                                    <span className="input-group-text">to</span>
                                    <IonDatetime
                                      displayFormat="h:mm A"
                                      pickerFormat="h:mm A"
                                      value={registerDetails.weekdayEndTime}
                                      defaultValue={
                                        registerDetails.weekdayEndTime
                                      }
                                      onIonChange={(ev: any) => {
                                        registerDetails.weekdayEndTime =
                                          ev.detail.value;
                                        setRegisterDetails(
                                          new RegisterModel(registerDetails)
                                        );
                                      }}
                                    ></IonDatetime>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <hr />
                          <div className="d-flex justify-content-between mb-2 align-items-center">
                            <label className="form-label fw-bolder">
                              Saturday
                            </label>
                            <IonToggle
                              mode="ios"
                              onIonChange={(ev) => {
                                registerDetails.isSaturdayWorking =
                                  ev.detail.checked;
                                if (!ev.detail.checked) {
                                  registerDetails.saturdayStartTime = "";
                                  registerDetails.saturdayEndTime = "";
                                }
                                setRegisterDetails(
                                  new RegisterModel(registerDetails)
                                );
                              }}
                            ></IonToggle>
                          </div>
                          {registerDetails.isSaturdayWorking === true ? (
                            <>
                              <div className="row">
                                <div className="col">
                                  <div className="input-group w-100">
                                    <IonDatetime
                                      displayFormat="h:mm A"
                                      pickerFormat="h:mm A"
                                      value={registerDetails.saturdayStartTime}
                                      defaultValue={
                                        registerDetails.saturdayStartTime
                                      }
                                      onIonChange={(ev: any) => {
                                        registerDetails.saturdayStartTime =
                                          ev.detail.value;
                                        setRegisterDetails(
                                          new RegisterModel(registerDetails)
                                        );
                                      }}
                                    ></IonDatetime>
                                    <span className="input-group-text">to</span>
                                    <IonDatetime
                                      displayFormat="h:mm A"
                                      pickerFormat="h:mm A"
                                      value={registerDetails.saturdayEndTime}
                                      defaultValue={
                                        registerDetails.saturdayEndTime
                                      }
                                      onIonChange={(ev: any) => {
                                        registerDetails.saturdayEndTime =
                                          ev.detail.value;
                                        setRegisterDetails(
                                          new RegisterModel(registerDetails)
                                        );
                                      }}
                                    ></IonDatetime>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}

                          <hr />
                          <div className="d-flex justify-content-between mb-2 align-items-center">
                            <label className="form-label fw-bolder">
                              Sunday
                            </label>
                            <IonToggle
                              mode="ios"
                              onIonChange={(ev) => {
                                registerDetails.isSundayWorking =
                                  ev.detail.checked;
                                if (!ev.detail.checked) {
                                  registerDetails.sundayStartTime = "";
                                  registerDetails.sundayEndTime = "";
                                }
                                setRegisterDetails(
                                  new RegisterModel(registerDetails)
                                );
                              }}
                            ></IonToggle>
                          </div>
                          {registerDetails.isSundayWorking === true ? (
                            <div className="row">
                              <div className="col">
                                <div className="input-group w-100">
                                  <IonDatetime
                                    displayFormat="h:mm A"
                                    pickerFormat="h:mm A"
                                    value={registerDetails.sundayStartTime}
                                    defaultValue={
                                      registerDetails.sundayStartTime
                                    }
                                    onIonChange={(ev: any) => {
                                      registerDetails.sundayStartTime =
                                        ev.detail.value;
                                      setRegisterDetails(
                                        new RegisterModel(registerDetails)
                                      );
                                    }}
                                  ></IonDatetime>
                                  <span className="input-group-text">to</span>
                                  <IonDatetime
                                    displayFormat="h:mm A"
                                    pickerFormat="h:mm A"
                                    value={registerDetails.sundayEndTime}
                                    defaultValue={registerDetails.sundayEndTime}
                                    onIonChange={(ev: any) => {
                                      registerDetails.sundayEndTime =
                                        ev.detail.value;
                                      setRegisterDetails(
                                        new RegisterModel(registerDetails)
                                      );
                                    }}
                                  ></IonDatetime>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {
                        // WORKSHOP STEP THREE
                        registerDetails.rate >= 1 &&
                        registerDetails.rate <= 5000 &&
                        registerDetails.rate_discount >= 5 &&
                        registerDetails.rate_discount <= 100 &&
                        registerDetails.parts_discount >= 5 &&
                        registerDetails.parts_discount <= 100 &&
                        (registerDetails.old_cars ||
                          registerDetails.new_cars) &&
                        registerDetails.vehicles.length >= 1 &&
                        ((registerDetails.weekdayStartTime !== "" &&
                          registerDetails.weekdayEndTime !== "") ||
                          (registerDetails.saturdayStartTime !== "" &&
                            registerDetails.saturdayEndTime !== "") ||
                          (registerDetails.sundayStartTime !== "" &&
                            registerDetails.sundayEndTime !== "")) ? (
                          <div className="stepper-footer">
                            <div></div>
                            <button
                              className="btn btn-warning btn-lg"
                              type="button"
                              onClick={() => {
                                // updateWorkshop(registerDetails);
                                setStep(4);
                                scrollToTop();
                                submitWorkshop();
                              }}
                            >
                              next
                            </button>
                          </div>
                        ) : null
                      }
                    </>
                  ) : null
                }
                {
                  // WORKSHOP STEP THREE
                  currentStep === 1 ? (
                    <>
                      {/* <button className="btn btn-outline-warning btn-lg mb-3" type="button" onClick={ () => {
                            scrollToTop();
                            updateWorkshop(workshop);
                            updateBusiness(business);
                            setStep(2);
                            history.push('/workshop/2');
                          }}>Back</button> */}
                      <div className="card mb-3">
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-3">
                            Please fill in your business details.
                          </h6>
                          <div className="spacer"></div>
                          <form>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Workshop Name
                              </label>
                              {/* <input
                                className="form-control"
                                type="tel"
                                value={registerDetails.workshop_name}
                                onChange={(ev) => {
                                  registerDetails.workshop_name =
                                    ev.target.value;
                                  setRegisterDetails(
                                    new RegisterModel(registerDetails)
                                  );
                                }}
                              /> */}
                              <ComboBox
                                className="form-control"
                                data={filterWorkShop}
                                textField="Workshop"
                                dataItemKey="Workshop"
                                filterable={true}
                                suggest={true}
                                // filter={workshopFiletr}
                                // onFilterChange={handleWorkshopFilterChange}
                                value={
                                  filterWorkShop.find(
                                    (workShop: any) =>
                                      workShop.Workshop ===
                                      registerDetails.workshop_name
                                  ) ||
                                  (registerDetails.workshop_name
                                    ? {
                                        Workshop: registerDetails.workshop_name,
                                      }
                                    : null)
                                }
                                onChange={(ev) => {
                                  const selected = ev.value;
                                  if (selected) {
                                    console.log("is it selected: ", selected);
                                    const updatedDetails = new RegisterModel({
                                      ...registerDetails,
                                      workshop_name: selected.Workshop,
                                      rmi: selected["RMI Number"] || "",
                                    });
                                    setRegisterDetails(updatedDetails);
                                    console.log("updated details selected: ", updatedDetails)
                                    setFilterWorkShop(workShopCompanyNames);
                                  } else {
                                    const updatedDetails = new RegisterModel({
                                      ...registerDetails,
                                      workshop_name: "",
                                      rmi: "",
                                    });
                                    setRegisterDetails(updatedDetails);
                                    console.log("else update: ", updatedDetails)
                                  }
                                }}
                              />
                            </div>

                            {/* this the RMI number section */}
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                RMI Number
                              </label>
                              <input
                                className="form-control"
                                value={registerDetails.rmi}
                                onChange={(ev: any) => {
                                  registerDetails.rmi = ev.target.value;
                                }}
                                type="text"
                                name="rmi"
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Search Address
                              </label>
                              <input
                                className="form-control"
                                id="location-search"
                                defaultValue={address}
                                value={registerDetails.address}
                                type="text"
                                onChange={(ev) => {
                                  setAddress(ev.target.value);
                                  setRegisterDetails((prev) => ({
                                    ...prev,
                                    address: ev.target.value,
                                  }));
                                }}
                                onInput={(ev) => {
                                  // @ts-ignore
                                  const inputValue = ev.target.value;
                                  setAddress(inputValue);

                                  // @ts-ignore
                                  initService(ev.target.value).then(
                                    (d: any) => {
                                      setLocations(d);
                                    }
                                  );
                                }}
                              />
                            </div>

                            {locations.length > 0
                              ? locations.map((l: any) => {
                                  return (
                                    <>
                                      <div
                                        className="result-list"
                                        onClick={() => {
                                          const addressValue =
                                            registerDetails.address;
                                          setAddress(addressValue);
                                          registerDetails.address =
                                            l.description;
                                          registerDetails.place_id = l.place_id;
                                          Geocode(l.description).then(
                                            (d: any) => {
                                              // @ts-ignore
                                              // document.getElementById(
                                              //   "location-search"
                                              // ).value = l.description;
                                              registerDetails.latlng = [
                                                d.geometry.location.lat(),
                                                d.geometry.location.lng(),
                                              ];
                                              setRegisterDetails(
                                                registerDetails
                                              );
                                              setLocations([]);
                                            }
                                          );
                                        }}
                                      >
                                        <p className="m-0 p-2">
                                          {l.description}
                                        </p>
                                      </div>
                                    </>
                                  );
                                })
                              : null}

                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Province
                              </label>
                              <DropDownList
                                data={provinces}
                                value={registerDetails.province}
                                textField="name"
                                dataItemKey="id"
                                onChange={(ev) => {
                                  registerDetails.province = ev.value;

                                  setRegisterDetails(
                                    new RegisterModel(registerDetails)
                                  );
                                }}
                                className="custom-dropdown"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="card mb-3">
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-3">
                            Provide your Company registration and VAT details.
                          </h6>
                          <div className="spacer"></div>
                          <form>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Company Registration No
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={registerDetails.reg_no}
                                onChange={(ev) => {
                                  registerDetails.reg_no = ev.target.value;
                                  setRegisterDetails(
                                    new RegisterModel(registerDetails)
                                  );
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Company VAT No
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={registerDetails.vat_no}
                                onChange={(ev) => {
                                  registerDetails.vat_no = ev.target.value;
                                  setRegisterDetails(
                                    new RegisterModel(registerDetails)
                                  );
                                }}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="card mb-3">
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-3">
                            Let’s get you paid!&nbsp;Fill in your company
                            banking details.
                          </h6>
                          <div className="spacer"></div>
                          <form>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Select Bank
                              </label>

                              <DropDownList
                                data={banks}
                                value={registerDetails.bank}
                                textField="name"
                                dataItemKey="id"
                                onChange={(ev) => {
                                  registerDetails.bank = ev.value;
                                  setRegisterDetails(
                                    new RegisterModel(registerDetails)
                                  );
                                }}
                                className="custom-dropdown"
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Account Type
                              </label>
                              <DropDownList
                                data={accounts}
                                value={registerDetails.bank_account_type}
                                textField="name"
                                dataItemKey="id"
                                onChange={(ev) => {
                                  registerDetails.bank_account_type = ev.value;
                                  setRegisterDetails(
                                    new RegisterModel(registerDetails)
                                  );
                                }}
                                className="custom-dropdown"
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Account No
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={registerDetails.bank_account_no}
                                onChange={(ev) => {
                                  registerDetails.bank_account_no =
                                    ev.target.value;
                                  setRegisterDetails(
                                    new RegisterModel(registerDetails)
                                  );
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Branch Code
                              </label>
                              <input
                                className="form-control w-50"
                                type="text"
                                value={registerDetails.bank_branch_code}
                                onChange={(ev) => {
                                  registerDetails.bank_branch_code =
                                    ev.target.value;
                                  setRegisterDetails(
                                    new RegisterModel(registerDetails)
                                  );
                                }}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                      {
                        <div className="stepper-footer">
                          <span>&nbsp;</span>
                          <button
                            disabled={
                              clicked ||
                              !registerDetails.bank ||
                              !registerDetails.bank_account_type ||
                              !registerDetails.bank_account_no ||
                              !registerDetails.bank_branch_code ||
                              !registerDetails.reg_no ||
                              !registerDetails.vat_no ||
                              !registerDetails.address ||
                              !registerDetails.place_id ||
                              !registerDetails.latlng ||
                              !registerDetails.workshop_name ||
                              !registerDetails.province
                            }
                            className="btn btn-warning btn-lg"
                            type="button"
                            onClick={() => {
                              submitWorkshop();
                              setStep(2);
                              history.push("/onboarding/2");
                            }}
                          >
                            Next
                            {clicked ? (
                              <IonSpinner name="crescent"></IonSpinner>
                            ) : null}
                          </button>
                        </div>
                      }
                    </>
                  ) : null
                }
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default Onboarding;
